import clsx from "clsx"

import {Button} from "../elements/Button"

type HeroProps = {
  category?: string
  title: string
  icon?: string
  description?: string
  label?: string
  url?: string
  size?: "normal" | "mega"
  backgroundColor?: string
  bgImage?: string
  mobileBgImage?: string
  bgImageOverlay?: boolean
  centered?: boolean
  callToAction?: React.ReactElement
}

export const Hero = ({
  category,
  title,
  icon,
  description,
  label,
  url,
  size = "normal",
  backgroundColor = "#1D2024",
  bgImage,
  mobileBgImage,
  bgImageOverlay,
  centered,
  callToAction,
}: HeroProps) => {
  const heroSizes = {
    normal: "lg:py-5",
    mega: "lg:py-7",
  }

  const backgroundClasses = clsx({
    ["bg-[#1D2024]"]: backgroundColor,
    ["relative h-full w-full"]: bgImage,
  })

  const foregroundClasses = clsx(
    {
      ["container-xl mx-auto py-4"]: true,
      ["flex flex-col items-center text-center"]: centered,
      ["relative z-20 h-full"]: bgImage,
    },
    heroSizes[size],
  )

  const categoryClasses = clsx({
    ["mb-1"]: true,
    ["text-smoke"]: !bgImage,
    ["text-white"]: bgImage,
  })

  const titleClasses = clsx({
    ["text-title max-w-[640px]"]: true,
    ["text-white"]: bgImage,
  })

  const descriptionClasses = clsx({
    ["mt-1 max-w-[640px]"]: true,
    ["text-ash"]: !bgImage,
    ["text-white"]: bgImage,
  })

  return (
    <div className={backgroundClasses}>
      {!!bgImage && !!bgImageOverlay && (
        <div className="absolute z-10 inset-0 w-[100%] h-[100%] bg-gradient-to-r from-[rgba(0,0,0,.45)]" />
      )}
      {!!bgImage && (
        <picture className="absolute h-full w-full">
          {!!mobileBgImage && (
            <source
              srcSet={`${mobileBgImage} 768w`}
              media="(max-width:768px)"
            />
          )}
          <img
            src={bgImage}
            className="h-full w-full object-cover"
            alt="Background"
          />
        </picture>
      )}
      <div className={foregroundClasses}>
        {!!category && <span className={categoryClasses}>{category}</span>}
        {icon ? (
          <img src={icon} alt={title} />
        ) : (
          <h2 className={titleClasses}>{title}</h2>
        )}

        {!!description && <p className={descriptionClasses}>{description}</p>}

        {callToAction ??
          (label && (
            <Button className="mt-2" as="link" to={url} size="lg">
              {label}
            </Button>
          ))}
      </div>
    </div>
  )
}
